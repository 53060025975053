.pause-chat-dialog__toggle {
    background: none; 
    border: none; 
    box-shadow: none;
    padding: 0;
    margin: 0;
    text-transform: none;
    color: #039be5;
    font-size: 1.8rem;
    height: auto !important;
    padding: 0 !important;
    line-height: 1.8rem !important;
    display: flex;
    letter-spacing: normal;
}

.pause-chat-dialog__toggle:hover {
    background: none; 
    border: none; 
    box-shadow: none;
}

.pause-chat-dialog__toggle:focus {
    background: none; 
}

.pause-chat-dialog__menu {
    border: 1px solid;
    width: 35rem;
    height: 20rem;
    transform: translate3D(6rem, -21rem, 0) !important;
    text-align: center;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 7px 1px rgb(0 0 0 / 75%);
    border-radius: 5px;
}

.pause-chat-dialog__select {
    display: block;
    width: 99%;
    margin-left: auto;
    margin-right: auto;
}

.pause-chat-dialog__action-btn {
    height: 2.7rem;
    font-size: 0.9rem;
    box-shadow: none;
    border-radius: 0;
    margin: auto auto 0.5rem auto;
    line-height: 0;
}

.pause-chat-dialog__footer {
    width: 100%;
    position: absolute;
    bottom: .5rem;
}