.chat__close-reason-container
{
    margin: auto;
    top: 250px;    
    width: 700px;
    max-width: none;
}

.close-reason-container__question {
    color: black;
}

.chat__close-reason-container .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    font-size: 2.6rem;
}

.close-reason-container__button {
    width: 130px;
    margin: 0px 10px 0px 10px !important;
}

.close-reason-container__select {
    margin: 20px 0px 20px 0px;
}

.close-reason-container_spinner {
    margin-left: auto;
    margin-right: auto;
    display: table;
}