.settings-list {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.settings-list__table {
    height: calc(100vh - 12.6rem);
    overflow: auto;
}

.settings-list-table th {
    padding-left: 20px;
}

.settings-list-table th, td {
    text-align: left;
}

.setting-create-button {
    float: left;
    margin: 1.5rem 4rem;
}

.setting-search-string {
    margin: 1.5rem 4rem;
    height: 4rem;
    line-height: 4rem;
}

.setting-delete-button {
    background-color: #d22e35;
    margin-left: .5rem;
}

.setting-buttons-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
}

.setting-cancel-button {
    background-color: #d22e37;
    float: left;
    margin: 1.5rem 4rem;
    margin-left: -2rem;
}

.setting-id-container {
    padding-left: 20px;
}