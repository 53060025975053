.profile-container {
    position: absolute;
    width: 46rem;
    height: 100%;
    right: 8.2rem;
    background-color: rgb(238, 238, 238);
    text-align: left;
    padding: 3.6rem 5.4rem;
    line-height: 3.6rem;
    z-index: 550;
}

.operator_list_container {
    font-size: medium;
    font-weight: 500;
    white-space: nowrap;
}

.operator_list_container td {
    padding: 0px 5px;
}

.profile-container__profile-block {
    margin-bottom: 3.6rem;
}

.profile-container__profile-channel-name{
    margin-right: 1.8rem;
}

.profile-container__profile-channel-type-container{
    margin-right: 1.8rem;
}

.channel-type-img {
    width: 2.1rem;
    height: 2.1rem;
}

.profile-container__profile-channel-type-1 {
    content: url("./../../shared/images/Chat/telegram.svg");
}

.profile-container__profile-channel-type-2 {
    content: url("./../../shared/images/Chat/viber.svg");
}

.profile-container__profile-channel-type-3 {
    content: url("./../../shared/images/Chat/vk.svg");
}

.profile-container__profile-channel-type-4 {
    content: url("./../../shared/images/Chat/widget.svg");
}

.profile-container__profile-channel-type-5 {
    content: url("./../../shared/images/Chat/facebook.svg");
}

.profile-container__profile-channel-type-6 {
    content: url("./../../shared/images/Chat/wechat.svg");
}

.profile-container__profile-channel-type-7 {
    content: url("./../../shared/images/Chat/instagram.svg");
}

.profile-container__profile-channel-type-9 {
    content: url("./../../shared/images/Chat/whatsapp-logo.svg");
}

.profile-container__profile-channel-type-10 {
    content: url("./../../shared/images/Chat/whatsapp-logo.svg");
}