.message-item {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    border-radius: 4px;
    max-width: 65%;
    overflow-wrap: break-word;
    padding: 10px 10px;
    margin: 5px;
    min-width: 70%;
}

.message-item__new-kb {
    position: absolute;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    line-height: 2rem;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    background-color: aliceblue;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.message-item__author-name {
    font-size: small;
    font-weight: bold;
    width: 100%;
}

.message-item__author-name.client-message-type {
    text-align: right;
    border-radius: 50px;
    padding-right: .4rem;
    background: linear-gradient(to right,  rgba(65, 191, 255, 0), rgba(233, 248, 255, 0.5), rgb(144, 218, 255));
}

.message-item__author-name.operator-message-type {
    text-align: left;
    border-radius: 50px;
    padding-left: .4rem;
    background: linear-gradient(to right, rgb(144, 218, 255), rgba(233, 248, 255, 0.5), rgba(65, 191, 255, 0));
}

.system-message-item {
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-size: 1.4rem;
}

.contact-info-container {
    width: 3rem;
    float: right;
    margin-right: 1rem;
    margin-top: 5px;
}

.operator-message-type {
    float: right;
}

.client-message-type {
    float: left;
}

.chat-info-messageType {
    min-width: 100% !important;
    height: 2rem;
    background-color: rgba(185, 196, 196, 0.4);
    font-size: 1.6rem;
    margin-top: 2rem;
    display: inline-table;
}

.message-text {
    text-align: left;
    white-space: pre-wrap;
    padding: 0 14px;
}

.message-text div {
    font-size: 1.6rem !important;
}

.message-time {
    text-align: right;
    font-size: 1.4rem;
}

.contact-name {
    font-size: 12px;
}

.contact-avatar img{
    width: 40px;
    border-radius: 50%;
}

.message-item__media {
    max-width: 100%;
    min-height: 5rem;
    max-height: 15rem;
}

.message-item__file_link {
    cursor: pointer;
    text-align: left;
    font-size: medium;
    margin-left: 1rem;
}

.location_iframe {
    width: -webkit-fill-available;
    height: 450px;
}

.rotate--icon {
    position: relative;
    display: none;
}

.rotate--image {
    position: relative;
}

.rotate--image:hover .rotate--icon {
    display: block;
}

.rotate--icon-image{
    position: absolute;
    left: 0;
    top: 0;
}

#content-type__label {
    font-family: "Material Icons" !important;
}

audio {
    width: -webkit-fill-available;
}

.wmde-markdown {
    white-space: initial;
}