.suggestions-list__menu {
    text-align: left;
    color: black;
    display: flex;
    flex-direction: column;
    top: 3.6rem !important;
    left: -4.9rem !important;
    z-index: 10000;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.suggestions-list__menu.closing-suggestions {
    overflow-y: auto;
    max-height: 30rem;
    overflow-x: hidden;
    overflow-wrap: anywhere;
    max-width: 60%;
}

.suggestions-list__tag-name {
    line-height: 1;
    margin: 0;
    color: black !important;
}

.suggestions-list__toggle {
    background: none; 
    border: none; 
    box-shadow: none;
    text-transform: none;
    font-size: 1rem;
    padding-top: 0px;
    cursor: inherit;
    margin-left: 5rem;
    width: 0 !important;
}

.suggestions-list__toggle:hover {
    background: none; 
    border: none; 
    box-shadow: none;
}

.suggestions-list__toggle:focus {
    background: none; 
}

.suggestions-list__suggestion {
    padding: 0.3rem 1.2rem 0.3rem 1.2rem;
    cursor: pointer;
}

.suggestions-list__suggestion:hover {
    background: #26a69a;
    color: #fff;
}

.tag-searching-input__container .dropdown {
    width: 0;
}

.suggestions-list__suggestion-operator {
    cursor: pointer;
    display: flex;
    padding: 0.3rem 1.2rem 0.3rem 1.2rem;
    margin-right: auto;
    margin: auto 0;
}

.suggestions-list__suggestion-operator:hover {
    background: #26a69a;
    color: #fff;
}

.suggestions-list__suggestion-operator .suggestion-operator__avatar {
    border-radius: 50%;
    margin: auto 0;
    margin-right: 1rem;
    box-shadow: 0px 0px 2px 1px #aaa;
    width: 30px;
    height: 30px;
}

.suggestions-list__suggestion-operator .suggestion-operator__avatar svg {
    width: 100% !important;
    height: 100% !important;
}

.suggestions-list__suggestion-operator .suggestion-contact__avatar {
    display: inline-block !important;
    border-radius: 50%;
    margin: auto 0;
    margin-right: 1rem;
    box-shadow: 0px 0px 2px 1px #aaa;
    width: 30px;
    height: 30px;
    }

.suggestions-list__suggestion-operator .suggestion-contact__avatar svg
{
    width: 100% !important;
    height: 100% !important;
}

.suggestion-operator__operator-info {
    display: flex;
    flex-direction: column;
    font-size: calc(1rem + 0.4vw);
    justify-content: center;
}

.suggestion-contact__contact-info {
    display: flex;
    flex-direction: column;
    font-size: calc(1rem + 0.4vw);
    justify-content: center;
}

.suggestion-contact__contact-phone {
    font-size: calc(0.8rem + 0.4vw);
}

.chat-tag-suggestion__dropdown {
    position: relative;
}

.chat-tag-suggestion__dropdown-list {
    position: absolute;
    left: 100%;
    top: 0;
    max-height: 20rem;
    overflow-y: auto;
    color: black;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.chat-tag-suggestion__dropdown-item {
    padding: 0.3rem 1.2rem 0.3rem 1.2rem;
}

.chat-tag-suggestion__dropdown-item:hover {
    background: #26a69a;
    color: #fff;
}

.chat-tag-suggestion__dropdown::after {
    display: inline-block;
    position: relative;
    top: 4px;
    left: 10px;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
}

.suggestion-chat-tag-value {
    width: 25em;
    white-space: nowrap;
    text-overflow: ellipsis;
}