.choose-file-dialog__container {
    position: relative;
    top: 5px;
}

.choose-file-dialog__menu{
    position: absolute;
    display: block;
    min-width: 4rem;
    bottom: 2rem;
    text-align: center;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    border-radius: 4px;
    z-index: 100;
}

.choose-file-dialog__menu-item {
    cursor: pointer;
    text-align: left;
    padding: 1rem 2rem;
}

.choose-file-dialog__menu-item:hover {
    background: #26a69a;
    color: #fff;
}

.choose-file-dialog__attach-image {
    transform: rotate(45deg);
    font-size: 2.2rem;
    cursor: pointer;
    opacity: 0.7;
}

.choose-file-dialog__attach-image:hover {
    opacity: 1;
}

.choose-file-dialog__label {
    cursor: pointer;
}

 .input-file__hidden {
    display: none;
} 