.topic-pairs-item {
  position: relative;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  padding-top: 1.8rem;
  padding-right: 3.6rem;
  padding-bottom: 1.8rem;
  padding-left: 3.6rem;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  min-width: 100rem;
}

.topic-pairs-item .topic-pairs-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topic-pairs-item .topic-pairs-item-header__content {
  display: inline-block;
}

.topic-pairs-item .topic-pairs-item-header__content-grow {
  flex: 1;
  text-align: left;
}

.topic-pairs-item-header__trigger_name {
  position: absolute;
  margin-left: 0.9rem;
  display: inline-flex;
  width: 27rem;
}

.topic-pairs-item-header__trigger_name input {
  /* height: 7.3rem !important; */
}

.topic-pairs-item-header__category {
  position: absolute;
  margin-left: 28.8rem;
  display: inline-flex;
  width: 27rem;
}

.topic-pairs-item-header__category input {
  /* height: 4rem !important; */
}

.topic-pairs-item .topic-pairs-item-header__identifier {
  font-size: 3.6rem;
}

.topic-pairs-item .topic-pairs-item-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;
}

.topic-pairs-item .topic-pairs-item__button {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.topic-pairs-item .topic-pairs-item__button-delete {
  background-color: #d22e37;
}

.topic-pairs-item .topic-pairs-item__button-active {
  background-color: #26a69a;
}

.topic-pairs-item .topic-pairs-item__button-create {
  background-color: #26a69a;
  width: 6rem;
  margin-bottom: 2rem;
}

.topic-pairs-item .topic-pairs-item__button-inactive {
  background-color: #d6ac17;
}

.topic-pairs-item .topic-pairs-item__button-pending {
  background-color: #1976d2;
}

.topic-pairs-item .topic-pairs-item__button-move {
  background-color: #1976d2;
}

.topic-pairs-item .topic-pairs-item__button-save {
  background-color: #d6ac17;
}

.topic-pairs-item .topic-pairs-item__button-discard {
  background-color: #d22e37;
}

.topic-pairs-item .topic-pairs-item__question-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topic-pairs-item .topic-pairs-item__question-editor {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-right: 5px;
}

.topic-pairs-item .topic-pairs-item__question-editor input {
  display: none;
}

.topic-pairs-item .topic-pairs-item__question-buttons {
  display: flex;
  flex-direction: row;
}

.topic-pairs-item__button-variant-answer {
  margin-right: 10.8rem;
  float: left;
}

.topic-pairs-item__button-variant-answer_checked {
  color: rgb(255, 255, 0);
}

.topic-pairs-item__question {
  display: flex;
  flex-direction: column;
}

.topic-pairs-item__variant-buttons {
  display: flex;
}

.topic-pairs-item__variant-buttons button {
  margin-right: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.nlp-button-line {
  width: 100%;
  display: block;
}

.nlp-button-img-change-pos {
  display: inline-block;
  width: 1.8rem;
  visibility: hidden;
  opacity: 0.5;
  margin-right: 0.5rem;
}

.nlp-button-img-change-pos.change-pos-up {
  transform: rotate(180deg);
}

.nlp-buttons-container {
  margin-left: auto;
  margin-right: auto;
}

.nlp-button {
  background-color: ghostwhite;
  display: inline-block;
  width: 60%;
  padding: 0.2rem;
  border: solid 1px black;
  border-radius: 10px;
  cursor: pointer;
}

.nlp-button-img {
  width: 2.7rem;
  float: left;
}

.nlp-buttons-management {
  display: inline-flex;
  visibility: hidden;
  color: blue;
  cursor: pointer;
  gap: 10px;
  margin-left: 1.8rem;
}

.nlp-button-line:hover .nlp-buttons-management {
  visibility: visible;
}

.nlp-button-line:hover .nlp-button-img-change-pos {
  visibility: visible;
}

.add-nlp-button {
  width: 3.6rem;
  margin-right: 3.6rem;
  cursor: pointer;
}

.edit-nlp-button-dialog-container span {
  display: block;
  margin-bottom: 1.8rem;
}

.dialog-nlp-button-type {
  display: block;
}

.nlp-button__name {
  word-break: break-word;
}

.topic-pair-content-type__input {
  font-size: 1.8rem;
  display: inline-block;
  width: 22rem;
  height: 4rem;
  margin-right: 2rem;
  vertical-align: middle;
}